import React from 'react'
import { useGetCase } from '@/services/kyc-monitoring'
import { PeopleAndCompanyCheckUnsubmitted } from './Application.PeopleAndCompanyChecksUnsubmitted'
import { PeopleAndCompanyCheckSubmitted } from './Application.PeopleAndCompanyChecksSubmitted'
import { PeopleAndCompanyCheckLoading } from './Application.PeopleAndCompanyCheckLoading'

interface SanctionsPanelProps {
    applicationId: string
}

const ApplicationPeopleAndCompanyChecksPanel: React.FC<SanctionsPanelProps> = (props) => {
    const { applicationId } = props

    const { data, isLoading } = useGetCase({ applicationId })

    const hasCases = data && data.case_summary && data.case_summary.length > 0

    if (isLoading) {
        return <PeopleAndCompanyCheckLoading />
    }

    if (!hasCases) {
        return <PeopleAndCompanyCheckUnsubmitted applicationId={applicationId} />
    }

    return <PeopleAndCompanyCheckSubmitted applicationId={applicationId} caseSummary={data.case_summary[0]} />
}

export { ApplicationPeopleAndCompanyChecksPanel }
