import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { List } from '../../../components/layout/list'
import { Spacer } from '../../../components/layout/spacer'
import { Grid } from '../../../components/layout/grid'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { RootState } from '@/store'
import { CountriesDictionaryByTimezone, CountriesListByTimezone } from '../../../utils/countries'
import { Flex } from '../../../components/layout/flex'
import { MerchantAccountBAIs, MerchantAccountBAIsPlusEvenUnusedOnes, MerchantAccountTTIs, MerchantAccountTTIsDictionary } from '../../../store/merchantAccounts/types'
import { ExternalLink } from '../../../components/buttons/externalLink'
import { HelpTip } from '../../../components/general/helpTip'
import { Text } from '../../../components/general/text'
import { AvailableSettlementCurrencies } from '../../../store/settlements/types'

const YESNO = [true, false]

export const MerchantAccountDetails: React.FC<{ accountId: string; formRef: any; errors?: any; data: any }> = ({
    accountId,
    formRef,
    errors,
    data
}) => {
    const dispatch = useDispatch()
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])

    useEffect(() => {
        if (!accountId) return
        if (!account) {
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
            return
        }
        if (account.loadingStatus !== 'started' && account.loadingStatus !== 'done')
            dispatch(MerchantAccountsActions.FETCH_ACCOUNT(accountId))
    }, [account, accountId, dispatch])

    const textForTimezone = useCallback((id: any) => {
        if (!id) return ''
        return `${CountriesDictionaryByTimezone?.[id]?.name} - ${CountriesDictionaryByTimezone?.[id]?.timezone}`
    }, [])

    return (
        <Card title={'Account settings'} showEditable>
            <CardInset>
                <Spacer height={10} />
                <Flex column>
                    <Grid horizontalTemplate="1fr 1fr" verticalTemplate="auto" switchToRowsAt={1500}>
                        <List
                            background="front.background"
                            items={{
                                'Name': {
                                    key: 'name',
                                    type: 'input',
                                    placeholder: 'Account name',
                                    initialValue: account?.name,
                                    overBackground: 'front.background',
                                    validation: errors.name,
                                    ref: (ref) => formRef(ref, 'name', yup.string().required())
                                },
                                'MCC': {
                                    key: 'mcc',
                                    type: 'autocomplete',
                                    allowAnyInput: true,
                                    placeholder: 'MCC',
                                    forEntity: 'mcc',
                                    selected: account?.mcc,
                                    dropdownId: 'Merchant.AccountsPage.Account.MCCList',
                                    overBackground: 'front.background',
                                    validation: errors.mcc,
                                    noClear: true,
                                    ref: (ref) => formRef(ref, 'mcc', yup.string().required())
                                },
                                'Currency': {
                                    key: 'currency',
                                    type: 'select',
                                    textForItem: currencyTextForItem,
                                    isDisabled: !!account?.contract,
                                    noClear: true,
                                    lazyLoaded: true,
                                    selected: account?.currency,
                                    placeholder: 'Select a currency',
                                    overBackground: 'front.background',
                                    dropdownId: 'Merchant.AccountsPage.Account.CurrencyList',
                                    items: AvailableSettlementCurrencies,
                                    validation: errors.currency,
                                    ref: (ref) => formRef(ref, 'currency', yup.string().required())
                                },
                                'Clearhaus Billing': {
                                    key: 'clearhaus-billing',
                                    type: 'select',
                                    textForItem: questionTextForItem,
                                    selected: account?.chBilling,
                                    isDisabled: true,
                                    overBackground: 'front.background',
                                    dropdownId: 'Merchant.AccountsPage.Account.Billing',
                                    items: YESNO,
                                    isQuestion: true,
                                    validation: errors.chBilling,
                                    ref: (ref) => formRef(ref, 'chBilling', yup.string())
                                },
                                'PH (Payments held)': {
                                    key: 'payments-held',
                                    type: 'select',
                                    textForItem: questionTextForItem,
                                    selected: account?.paymentsHeld,
                                    shouldHide:
                                        data.chBilling !== undefined ? data.chBilling !== true : !account?.chBilling,
                                    overBackground: 'front.background',
                                    dropdownId: 'Merchant.AccountsPage.Account.PaymentsHeld',
                                    items: YESNO,
                                    noClear: true,
                                    isQuestion: true,
                                    validation: errors.paymentsHeld,
                                    ref: (ref) => formRef(ref, 'paymentsHeld', yup.string())
                                }
                            }}
                            switchToRowsAt={10000}
                            cellHorizontalTemplate="140px minmax(auto, 1fr)"
                        />
                        <Flex column>
                            <List
                                background="front.background"
                                items={{
                                    'Descriptor': {
                                        key: 'descriptor',
                                        type: 'input',
                                        placeholder: 'Account descriptor',
                                        overBackground: 'front.background',
                                        initialValue: account?.descriptor,
                                        validation: errors.descriptor,
                                        ref: (ref) => formRef(ref, 'descriptor', yup.string().required().descriptor())
                                    },
                                    'Timezone': {
                                        key: 'timezone',
                                        type: 'select',
                                        placeholder: 'Timezone',
                                        items: CountriesListByTimezone,
                                        selected: account?.timezone,
                                        textForItem: textForTimezone,
                                        dropdownId: 'Merchant.AccountsPage.Account.CountriesList',
                                        overBackground: 'front.background',
                                        noClear: true,
                                        validation: errors.timezone,
                                        ref: (ref) => formRef(ref, 'timezone', yup.string().required())
                                    },
                                    'Parent MID': {
                                        key: 'parent-mid',
                                        type: 'input',
                                        initialValue: account?.parentMid,
                                        placeholder: 'Enter Parent MID',
                                        overBackground: 'front.background',
                                        validation: errors.parentMid,
                                        ref: (ref) =>
                                            formRef(
                                                ref,
                                                'parentMid',
                                                yup
                                                    .string()
                                                    .matches(/^[0-9]*$/, 'Should only contain digits')
                                                    .length(8, 'Should be 8 digits')
                                                    .required()
                                            )
                                    },
                                    'BAI': {
                                        key: 'bai',
                                        type: 'select',
                                        placeholder: '-',
                                        items: MerchantAccountBAIs,
                                        selected: account?.businessApplicationIdentifier,
                                        textForItem: (k) => {
                                            if (!k) return ''
                                            return `${k} - ${
                                                (MerchantAccountBAIsPlusEvenUnusedOnes as any)?.[k] || 'Unknown'
                                            }`
                                        },
                                        itemForText: (k) => {
                                            return k.split(' - ')[0]
                                        },
                                        dropdownId: 'Merchant.AccountsPage.Account.BAI',
                                        overBackground: 'front.background',
                                        helpNode: (
                                            <HelpTip>
                                                <Text color="floating.text">
                                                    Visa&apos;s&nbsp;
                                                    <ExternalLink
                                                        defaultColor="floating.subtleAccent.text"
                                                        target="blank"
                                                        // eslint-disable-next-line max-len
                                                        url="https://developer.visa.com/guides/request_response_codes#business_application_identifier"
                                                    >
                                                        Business Application Identifier
                                                    </ExternalLink>
                                                </Text>
                                            </HelpTip>
                                        ),
                                        ref: (ref) => formRef(ref, 'business_application_identifier', yup.string())
                                    },
                                    'TTI': {
                                        key: 'tti',
                                        type: 'select',
                                        placeholder: '-',
                                        items: MerchantAccountTTIs,
                                        selected: account?.transactionTypeIdentifier,
                                        textForItem: (k) => {
                                            if (!k) return ''
                                            return `${k} - ${
                                                (MerchantAccountTTIsDictionary as any)?.[k] || 'Unknown'
                                            }`
                                        },
                                        itemForText: (k) => {
                                            return k.split(' - ')[0]
                                        },
                                        dropdownId: 'Merchant.AccountsPage.Account.TTI',
                                        overBackground: 'front.background',
                                        helpNode: (
                                            <HelpTip>
                                                <Text color="floating.text">
                                                    Transaction Type Identifier (Mastercard MoneySend)
                                                </Text>
                                            </HelpTip>
                                        ),
                                        ref: (ref) => formRef(ref, 'transaction_type_identifier', yup.string())
                                    },
                                    'Trusted': {
                                        key: 'trusted',
                                        type: 'select',
                                        textForItem: questionTextForItem,
                                        selected: account?.trusted,
                                        overBackground: 'front.background',
                                        dropdownId: 'Merchant.AccountsPage.Account.Trusted',
                                        items: YESNO,
                                        noClear: true,
                                        isQuestion: true,
                                        validation: errors.trusted,
                                        ref: (ref) => formRef(ref, 'trusted', yup.string())
                                    }
                                }}
                                switchToRowsAt={10000}
                                cellVerticalTemplate="min-content"
                                cellHorizontalTemplate="140px minmax(auto, 1fr)"
                            />
                        </Flex>
                    </Grid>
                </Flex>
            </CardInset>
        </Card>
    )
}

const questionTextForItem = (e: any) => {
    if (e === false) return 'No'
    if (e === true) return 'Yes'
    return undefined
}

const currencyTextForItem = (e: any) => {
    if (!e) return undefined
    return e
}
