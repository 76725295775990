import { api } from '../api'
import { Case, CaseSummary } from '../types/cases'

export const casesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCase: build.query<Case, { applicationId: string }>({
            query: ({ applicationId }) => `/applications/${applicationId}/case`,
            providesTags: ['case']
        }),
        createCase: build.mutation<CaseSummary, { applicationId: string }>({
            query: ({ applicationId }) => ({
                url: `/applications/${applicationId}/case`,
                method: 'POST'
            }),
            invalidatesTags: ['case']
        })
    })
})

export const { useGetCaseQuery: useGetCase, useCreateCaseMutation: useCreateCase } = casesApi
