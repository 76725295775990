import { ApplicationCheck } from './Application.Check'

const PeopleAndCompanyCheckLoading = () => {
    return (
        <ApplicationCheck>
            <ApplicationCheck.Loader />
        </ApplicationCheck>
    )
}

export { PeopleAndCompanyCheckLoading }
