import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { ToastsDispatchPush } from './toasts/actions'
import { AppDispatch } from './store'
import { BuildErrorToastContext } from '@/components/toasts/toasts'

interface MetaArg {
    endpointName?: string
}

interface Payload {
    status?: { status: number } | number
    data?: {
        statusCode?: number
        error?: string
        message?: string
    }
}

export const rtkQueryErrorLogger: Middleware = (store: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.warn(action)

        const payload = action.payload as Payload
        const endpointName = (action.meta?.arg as MetaArg)?.endpointName
        const message = endpointName
            ? `Error when making request to ${endpointName} endpoint`
            : 'Error when making request'

        const context = BuildErrorToastContext(undefined, payload.data)

        const statusCode = payload?.data?.statusCode

        if (statusCode && statusCode >= 400) {
            const dispatch = store.dispatch as AppDispatch
            dispatch(ToastsDispatchPush(message, 'error', undefined, undefined, context))
        }
    }

    return next(action)
}
