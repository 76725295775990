import { GenerateIndexPathForSection } from './Application.Structure'
import { MerchantApplicationSection } from './Application.Section'
import { CardInset } from '../../../components/cards/cardInset'
import { Card } from '../../../components/cards/card'
import { MerchantApplicationInternalDetails } from './Application.InternalDetails'
import styled, { css } from 'styled-components'
import { getHighlightTypeForField } from './utils'
import { MerchantApplicationSuperField } from './Application.SuperField'
import { Spacer } from '../../../components/layout/spacer'
import { Flex } from '../../../components/layout/flex'
import { RelatedApplicationsWarning } from './Application.RelatedApplicationsWarning'
import { DedicatedSectionProps } from './Application.DedicatedSection'
import { useDedicatedSection } from './Application.DedicatedSection'
import { useHasApplicationSectionLoaded } from './useHasApplicationSectionLoaded'
import { useHaveApplicationInternalDetailsLoaded } from './useHaveApplicationInternalDetailsLoaded'
import { TextLineLoader } from '../../../components/loaders/textLineLoader'
import { MerchantApplicationSectionCheckmark } from './Application.SectionCheckmark'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useMemo } from 'react'
import { Icon } from '../../../components/icons/icon'
import { ApplicationStatus } from '../../../store/applications/types'
import { ApplicationDedicatedInternalSectionWrapper } from './Application.DedicatedInternalSectionWrapper'
import { ApplicationDataProvidersPanel } from './Application.DataProviderPanel'
import { SummaryApplicationState } from '../Summary/SummaryApplicationState'
import { ApplicationSchemesScreeningPanel } from './Application.SchemesScreeningPanel'
import { ApplicationPeopleAndCompanyChecksPanel } from './Application.PeopleAndCompanyChecksPanel'

export const MerchantApplicationDedicatedSectionCompany: React.FC<DedicatedSectionProps> = ({
    errors,
    executeBatchChanges,
    applicationId,
    formRef
}) => {
    const company = useDedicatedSection(applicationId, 'Company')
    const sanitisedCompany = useDedicatedSection(applicationId, 'Sanitised Company')
    const hasLoaded = useHasApplicationSectionLoaded(applicationId, 'company', 'data')
    const haveInternalDetailsLoaded = useHaveApplicationInternalDetailsLoaded(applicationId)
    const state = useSelector((state: RootState) => state.applications.applications.at[applicationId]?.metadata?.state)
    const tagsState = useSelector((state: RootState) => state.applicationInternals.tags.forApplication[applicationId])

    const isInstantFlow = useMemo(() => {
        return tagsState?.selected.find((id) => {
            return tagsState.at[id].name === 'instant-flow'
        })
            ? true
            : false
    }, [tagsState])

    return (
        <>
            <Block state={state} data-cy="app-state-overview">
                <SummaryApplicationState applicationId={applicationId} />
                {isInstantFlow ? (
                    <Instant>
                        Instant flow{' '}
                        <IconOffset>
                            <Icon type="lightning" />
                        </IconOffset>
                    </Instant>
                ) : undefined}
            </Block>
            <div />
            <Holder data-cy="company" id="company">
                {company.structure.reviewableResource && (
                    <MerchantApplicationSectionCheckmark
                        applicationId={applicationId}
                        resource={company.structure.reviewableResource}
                    />
                )}
                <Card showEditable grow>
                    <CardInset>
                        <MerchantApplicationSuperField
                            applicationId={applicationId}
                            indexPath={GenerateIndexPathForSection(company.field)}
                            executeBatchChanges={executeBatchChanges}
                            highlighted={getHighlightTypeForField(company.structure.fields, 0)}
                            formErrors={errors}
                            formRef={formRef}
                            isDataLoading={!hasLoaded}
                        />
                        <Spacer height={15} />
                        <MerchantApplicationSection
                            key={'key'}
                            formErrors={errors}
                            isDataLoading={!hasLoaded}
                            nextDynamicSection={undefined}
                            executeBatchChanges={executeBatchChanges}
                            sectionStructure={company.structure}
                            formRef={formRef}
                            applicationId={applicationId}
                        />
                    </CardInset>
                </Card>
            </Holder>
            <Flex column>
                <Card background="back.background.strongerI" shadowLess>
                    <CardInset>
                        <ApplicationDataProvidersPanel id={applicationId} />
                    </CardInset>
                </Card>
                <Spacer height={10} />
                <ApplicationSchemesScreeningPanel applicationId={applicationId} />
                <Spacer height={10} />
                <ApplicationPeopleAndCompanyChecksPanel applicationId={applicationId} />
                <Spacer height={10} />
                <ApplicationDedicatedInternalSectionWrapper>
                    <Card background="back.background.strongerI" shadowLess>
                        <CardInset>
                            {haveInternalDetailsLoaded ? (
                                <RelatedApplicationsWarning id={applicationId} />
                            ) : (
                                <TextLineLoader height={29} maxWidth={300} />
                            )}
                        </CardInset>
                    </Card>
                    <Spacer height={10} />
                    <MerchantApplicationInternalDetails
                        sectionStructure={company.structure}
                        applicationId={applicationId}
                    />
                    <Spacer height={10} />
                    <Card showEditable background="back.background.strongerI" shadowLess>
                        <CardInset>
                            <MerchantApplicationSection
                                key={'key'}
                                formErrors={errors}
                                sanitised
                                isDataLoading={!hasLoaded}
                                nextDynamicSection={undefined}
                                executeBatchChanges={executeBatchChanges}
                                sectionStructure={sanitisedCompany.structure}
                                formRef={formRef}
                                applicationId={applicationId}
                            />
                        </CardInset>
                    </Card>
                </ApplicationDedicatedInternalSectionWrapper>
            </Flex>
        </>
    )
}

const Holder = styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
`

const Block = styled.div<{ state: ApplicationStatus }>`
    padding: 15px 25px;
    border-radius: 10px;
    ${(p) => {
        switch (p.state) {
            case 'submitted':
                return css`
                    background-color: ${p.theme['front.subtleAccent.background']};
                    color: ${p.theme['front.subtleAccent.text']};
                `
            case 'under_review':
                return css`
                    background-color: ${p.theme['front.subtleInfo.background']};
                    color: ${p.theme['front.subtleInfo.text']};
                `
            case 'pre_accepted':
                return css`
                    background-color: ${p.theme['front.subtleInfo.background']};
                    color: ${p.theme['front.subtleInfo.text']};
                `
            case 'declined':
                return css`
                    background-color: ${p.theme['front.danger.color']};
                    color: ${p.theme['front.danger.text']};
                `
            case 'accepted':
                return css`
                    background-color: ${p.theme['front.success.color']};
                    color: ${p.theme['front.success.text']};
                `
            default:
                return css`
                    background-color: ${p.theme['back.background.strongerI']};
                    color: ${p.theme['front.text']};
                `
        }
    }};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    position: relative;
`

const Instant = styled.div`
    background-color: ${(p) => p.theme['front.accent.color']};
    color: #fff;
    padding: 2px 7px;
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 0 10px 0 10px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
`

const IconOffset = styled.div`
    position: relative;
    top: 2px;
    margin-right: -5px;
`
