import { CallResReducer, CallsReducer } from './calls/reducers'
import { ApplicationDataProvidersReducer } from './applicationDataProviders/reducers'
import { ApplicationInternalsAgentsReducer } from './applicationInternals/agents/reducer'
import { ApplicationInternalsChecksReducer } from './applicationInternals/checks/reducers'
import { ApplicationInternalsCollaboratorsReducer } from './applicationInternals/collaborators/reducers'
import { ApplicationInternalsCompanyReducer } from './applicationInternals/company/reducer'
import { ApplicationInternalsDetailsReducer } from './applicationInternals/details/reducers'
import { ApplicationInternalsHarvesterReducer } from './applicationInternals/harvester/reducers'
import { ApplicationInternalsNeedingAttentionReducer } from './applicationInternals/needingAttention/reducers'
import { ApplicationInternalsRecordReducer } from './applicationInternals/record/reducers'
import { ApplicationInternalsReferralReducer } from './applicationInternals/referral/reducers'
import { ApplicationInternalsRelatedReducer } from './applicationInternals/related/reducers'
import { ApplicationInternalsStampingReducer } from './applicationInternals/stamping/reducers'
import { ApplicationInternalsTagsReducer } from './applicationInternals/tags/reducers'
import { ApplicationInternalsWarningsReducer } from './applicationInternals/warning/reducers'
import { ApplicationLoadingReducer } from './applicationLoading/reducers'
import { ApplicationResourcesReducer } from './applicationResources/reducers'
import { ApplicationsReducers } from './applications/reducers'
import { AuthReducer } from './auth/reducers'
import { AutocompleteReducers } from './autocomplete/reducers'
import { BVDReducer } from './applicationInternals/bvd/reducers'
import { CallsPrefReducer } from './calls-preferences/reducers'
import { ContractTemplatesReducer } from './contractTemplates/reducers'
import { ContractsReducers } from './contracts/reducers'
import { DashboardReducers } from './dashboard/reducers'
import { DisputesReducer } from './disputes/reducers'
import { ExportsReducer } from './exports/reducers'
import { FilesReducer } from './files/reducers'
import { GatewaysReducer } from './gateways/reducers'
import { GlobalReducer } from './global/reducers'
import { InterfaceReducer } from './interface/reducers'
import { MCCReducer } from './mcc/reducers'
import { MerchantAccountsReducer } from './merchantAccounts/reducers'
import { MerchantTimelineReducer } from './merchantTimeline/reducers'
import { SearchReducer } from './search/reducers'
import { SettlementsReducer } from './settlements/reducers'
import { SettlementsReservesReducer } from './settlementsReserves/reducers'
import { TasksReducer } from './tasks/reducers'
import { ToastsReducer } from './toasts/reducers'
import { TransactionsReducer } from './transactions/reducers'
import { UserAccountsReducer } from './userAccounts/reducers'
import { VouchersReducer } from './manage/vouchers/reducers'
import { WatchersReducer } from './watcher/reducers'
import { combineReducers } from '@reduxjs/toolkit'
import { api as merchantApi } from '@/services/merchant-api'
import { api as ongoingDueDiligenceApi } from '@/services/ongoing-due-diligence'
import { api as reconciliationApi } from '@/services/reconciliation'
import { api as riskClassificationApi } from '@/services/risk-classification'
import { api as taskrApi } from '@/services/taskr'
import { api as kycMonitoringApi } from '@/services/kyc-monitoring'
import { TasksCompanyChangesReducer } from './tasksCompanyChanges/reducers'
import { ApplicationInternalsSchemesScreeningReducer } from './applicationInternals/schemesScreening/reducers'

export const createReducers = () =>
    combineReducers({
        // createApi reducers
        [ongoingDueDiligenceApi.reducerPath]: ongoingDueDiligenceApi.reducer,
        [reconciliationApi.reducerPath]: reconciliationApi.reducer,
        [riskClassificationApi.reducerPath]: riskClassificationApi.reducer,
        [taskrApi.reducerPath]: taskrApi.reducer,
        [merchantApi.reducerPath]: merchantApi.reducer,
        [kycMonitoringApi.reducerPath]: kycMonitoringApi.reducer,
        // Other reducers
        disputes: DisputesReducer,
        auth: AuthReducer,
        global: GlobalReducer,
        watchers: WatchersReducer,
        dashboard: DashboardReducers,
        contractTemplates: ContractTemplatesReducer,
        applications: ApplicationsReducers,
        gateways: GatewaysReducer,
        userAccounts: UserAccountsReducer,
        merchantAccounts: MerchantAccountsReducer,
        tasks: TasksReducer,
        tasksCompanyChanges: TasksCompanyChangesReducer,
        autocomplete: AutocompleteReducers,
        search: SearchReducer,
        transactions: TransactionsReducer,
        contracts: ContractsReducers,
        tags: ApplicationInternalsTagsReducer,
        applicationLoading: ApplicationLoadingReducer,
        settlements: SettlementsReducer,
        settlementsReserves: SettlementsReservesReducer,
        calls: CallsReducer,
        callRes: CallResReducer,
        callsPref: CallsPrefReducer,
        interface: InterfaceReducer,
        toasts: ToastsReducer,
        files: FilesReducer,
        mcc: MCCReducer,
        exports: ExportsReducer,
        manage: combineReducers({
            vouchers: VouchersReducer
        }),
        applicationResources: ApplicationResourcesReducer,
        merchantTimeline: MerchantTimelineReducer,
        dataProviders: ApplicationDataProvidersReducer,
        applicationInternals: combineReducers({
            checks: ApplicationInternalsChecksReducer,
            record: ApplicationInternalsRecordReducer,
            collaborators: ApplicationInternalsCollaboratorsReducer,
            bvd: BVDReducer,
            company: ApplicationInternalsCompanyReducer,
            tags: ApplicationInternalsTagsReducer,
            stamping: ApplicationInternalsStampingReducer,
            agents: ApplicationInternalsAgentsReducer,
            needingAttention: ApplicationInternalsNeedingAttentionReducer,
            details: ApplicationInternalsDetailsReducer,
            referral: ApplicationInternalsReferralReducer,
            related: ApplicationInternalsRelatedReducer,
            harvester: ApplicationInternalsHarvesterReducer,
            warnings: ApplicationInternalsWarningsReducer,
            schemesScreening: ApplicationInternalsSchemesScreeningReducer
        })
    })
