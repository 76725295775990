import _, { camelCase } from 'lodash'
import moment from 'moment'

import {
    MapCutterIdToMAPIId,
    MerchantApplicationStructure
} from '../../pages/Merchant/Application/Application.Structure'
// eslint-disable-next-line max-len
import { TMerchantApplicationResourceKey } from '../../pages/Merchant/Application/Application.StructureTypes'
import {
    ApplicationResourceHistory,
    MerchantApplicationResourceIndexPath,
    MerchantApplicationResourceIndexPathString
} from './types'

const TAG_PREFIX = 'ch-field'

export function ApplicationResourcePersonConvertRoleBoolsToString({
    director,
    owner
}: {
    director: boolean
    owner: boolean
}): string | undefined {
    if (director && owner) return 'director-and-owner'
    if (director) return 'director'
    if (owner) return 'owner'
    return undefined
}

export const FieldCommentTag = {
    toLocal: (tags: string[]) => {
        const localTags = [...tags]
        const path = localTags[0].split(':')[1].split('.')
        const fieldName = `${TAG_PREFIX}:${path[path.length - 1]}`
        localTags[0] = fieldName
        return localTags
    },
    getRemote: (sectionName: string, fieldName: string) => {
        let tag
        switch (sectionName) {
            case 'businessModel':
                tag = `ch-field:businessModel.${fieldName}`
                break
            case 'signer':
                tag = `ch-field:signer.${fieldName}`
                break
            default:
                tag = `ch-field:${fieldName}`
                break
        }
        return tag
    }
}

function convertApplicationFilesPathsToLabelDictatedPaths(body: any) {
    const applicationFilesHolder: any = {}
    Object.keys(body).forEach((key: string) => {
        // Merge the application files differnet fields under a single field
        if (key.includes('applicationFiles')) {
            const pathSegments = key.split('.')
            let end = pathSegments.length - 1
            // take the path up to the files index
            const prefixSegments = _.takeWhile(pathSegments, (s, i) => {
                if (s === 'applicationFiles') {
                    end = i + 2
                }
                return i < end
            })
            const newPath = prefixSegments.join('.')
            if (!applicationFilesHolder[newPath]) applicationFilesHolder[newPath] = {}
            const label = pathSegments.pop() as string
            // create a new path with the file labels as fields

            applicationFilesHolder[newPath] = {
                ...applicationFilesHolder[newPath],
                [label]: body[key]
            }
        } else {
            applicationFilesHolder[key] = body[key]
        }
    })

    // replace every path containing the file index, with it's own file label
    // people.data.history.1.applicationFiles.6 -> people.data.history.1.addressLegitimation
    Object.keys(applicationFilesHolder).forEach((k) => {
        if (k.includes('applicationFiles')) {
            const segments = k.split('.')

            if (applicationFilesHolder[k]?.label && applicationFilesHolder[k]?.label[0]?.to) {
                const trimmedSegments = _.takeWhile(segments, (s) => s !== 'applicationFiles')

                // create the new path by appending the label to the prefix,
                // if prefix is 0 don't append, but set instead
                let newPath = `${trimmedSegments.join('.')}.${applicationFilesHolder[k]?.label[0].to}`

                if (trimmedSegments.length < 1) newPath = applicationFilesHolder[k].label[0].to

                applicationFilesHolder[newPath] = {
                    ...applicationFilesHolder[k]
                }

                if (newPath !== k) delete applicationFilesHolder[k]
            }
        }
    })

    return applicationFilesHolder
}

function rewriteKey(o: any, oldKey: string, newKey: string) {
    if (oldKey !== newKey) {
        o[newKey] = o[oldKey] ? JSON.parse(JSON.stringify(o[oldKey])) : undefined
        delete o[oldKey]
    }
}

function fixPathsInconsistencies(body: any): {
    [key: string]: ApplicationResourceHistory
} {
    const newBody = { ...body }

    // Rename plural bank accounts
    Object.keys(newBody).forEach((k: string) => {
        if (k.includes('bankAccounts')) {
            rewriteKey(newBody, k, k.replace('bankAccounts.0', 'bankAccount'))
        }
        if (k === 'businessModel') {
            rewriteKey(newBody, k, 'businessModel.description')
        }
        if (
            [
                'tradingName',
                'deliveryPhysical',
                'deliveryDelay',
                'dropShipping',
                'estimateAverageTransactionAmount',
                'estimateMonthlyTurnoverCurrency',
                'estimateMonthlyTurnover',
                'recurring'
            ].includes(k)
        )
            rewriteKey(newBody, k, `businessModel.${k}`)
        if (k.includes('sanitised')) {
            const [section, fieldName] = k.replace('sanitised', '').toLowerCase().split('.')
            const newKey = `${section}.sanitised.${fieldName}`
            rewriteKey(newBody, k, newKey)
        }
        if (k.includes('roleOwner') || k.includes('roleDirector')) {
            const newKey = k.replace('roleOwner', 'role').replace('roleDirector', 'role')
            if (k !== 'role') {
                const o = newBody
                o[newKey] = [
                    ...(o[newKey]
                        ? o[newKey].map((t: any) => ({
                              role: k.includes('roleOwner') ? 'owner' : 'director',
                              ...t
                          }))
                        : []),
                    ...(o[k]
                        ? JSON.parse(
                              JSON.stringify(
                                  o[k].map((t: any) => ({
                                      role: k.includes('roleOwner') ? 'owner' : 'director',
                                      ...t
                                  }))
                              )
                          )
                        : [])
                ]
                delete o[k]
            }
        }
        if (k.includes('signerEmail')) {
            rewriteKey(newBody, k, 'signer.signer.email')
        }
        if (k.includes('signerName')) {
            rewriteKey(newBody, k, 'signer.signer.name')
        }
        if (k === 'additional_file') {
            rewriteKey(newBody, k, 'additionalInformation.additionalFile')
        }

        if (k === 'additionalInfo') {
            rewriteKey(newBody, k, 'additionalInformation.additionalInformation')
        }

        if (k === 'gatewayId') {
            rewriteKey(newBody, k, 'gateway.gateways.0.id')
        }
    })

    const bodyKeys = Object.keys(newBody)

    if (!bodyKeys.includes('additional_file') || !bodyKeys.includes('additionalInfo')) {
        newBody['additionalInformation.additionalFile'] = undefined
    }

    // Rename cutter paths to mapi paths
    bodyKeys.forEach((k: string) => {
        const [section, ...rest] = k.split('.')
        const field = rest[rest.length - 1]
        rest.pop()

        if (field && MapCutterIdToMAPIId[`${section}.${camelCase(field)}`]) {
            const newKey = [section, ...rest, MapCutterIdToMAPIId[`${section}.${camelCase(field)}`]].join('.')
            rewriteKey(newBody, k, newKey)
        }
    })

    // Add subsectionIndex and fields to all paths
    Object.keys(newBody).forEach((k: string) => {
        const [resource, ...segments]: any = k.split('.')
        let key = `${resource}.fields.0.${segments.join('.')}`

        MerchantApplicationStructure.forEach((sectionStructure) => {
            if ('resource' in sectionStructure && sectionStructure.resource === resource)
                key = `${resource}.fields.${segments.join('.')}`
        })
        rewriteKey(newBody, k, key)
    })

    // Camel case all the keys for consistency reasons

    Object.keys(newBody).forEach((k: string) => {
        const keys = k.split('.')
        keys[keys.length - 1] = camelCase(keys[keys.length - 1])
        newBody[keys.join('.')] = newBody[k]
        // delete only if the new key isn't the same one as before
        if (keys.join('.') !== k) delete newBody[k]
    })

    return newBody
}

export function ConvertIndexPathStringToIndexPath(
    key: MerchantApplicationResourceIndexPathString
): MerchantApplicationResourceIndexPath | undefined {
    const segments = key.split('.')
    const [section, subsectionIndex, ...fieldKey] = segments
    if (segments.length < 3) throw `Unexpected IndexPathString containing less than 3 '.' separated segments: ${key}`

    return {
        resourceKey: section as TMerchantApplicationResourceKey,
        subsectionIndex: parseInt(subsectionIndex),
        fieldKey: fieldKey.join('.')
    }
}

export function ConvertIndexPathToIndexPathString(
    indexPath: MerchantApplicationResourceIndexPath
): MerchantApplicationResourceIndexPathString {
    return `${indexPath.resourceKey}.${indexPath.subsectionIndex || 0}.${indexPath.fieldKey}`
}

export function migrateDoubleRolesToRole(newPaths: any) {
    Object.keys(newPaths).forEach((fieldPath) => {
        if (fieldPath.startsWith('people.') && fieldPath.includes('role')) {
            const changes = newPaths[fieldPath]
            changes.sort((a: any, b: any) => {
                if (moment(a.at).isBefore(moment(b.at))) return -1
                return 1
            })
            let carry = {
                director: false,
                owner: false
            }
            changes.forEach((c: any) => {
                const newCarry = {
                    ...carry,
                    [c.role]: c.to
                }
                c.from = ApplicationResourcePersonConvertRoleBoolsToString({
                    ...carry
                })
                c.to = ApplicationResourcePersonConvertRoleBoolsToString(newCarry)
                carry = { ...newCarry }
            })
        }
    })

    // Merge double edits..
    Object.keys(newPaths).forEach((fieldPath) => {
        if (fieldPath.startsWith('people.') && fieldPath.includes('role')) {
            const changes = newPaths[fieldPath]
            for (let i = 0; i < changes.length - 1; i++) {
                if (changes[i].by === changes[i + 1].by && changes[i].at === changes[i + 1].at) {
                    changes[i].to = changes[i + 1].to
                    changes[i + 1].deleted = true
                }
            }
            // eslint-disable-next-line no-param-reassign
            newPaths[fieldPath] = newPaths[fieldPath].filter((i: any) => !i.deleted)
        }
    })

    return newPaths
}

export function CutterFieldPathsToApplicationStructureFieldPaths(body: any) {
    // Convert all paths with 'applicationFiles' to paths dictated by the file label, instead of the file index
    let newPaths = convertApplicationFilesPathsToLabelDictatedPaths(body)
    newPaths = fixPathsInconsistencies(newPaths)
    newPaths = migrateDoubleRolesToRole(newPaths)
    return newPaths
}
export const flattenObject = (obj: any = {}) => {
    const result: any = {}

    const flatten = (collection: any, prefix: any = '', suffix: any = '') => {
        _.forEach(collection, (value, key) => {
            const path = `${prefix}${key}${suffix}`

            if (_.isArray(value)) {
                flatten(value, `${path}[`, ']')
            } else if (_.isPlainObject(value)) {
                flatten(value, `${path}.`)
            } else {
                result[path] = value
            }
        })
    }

    flatten(obj)

    return result
}
