import { LoadingStatus } from '../../utils'

export interface MerchantAccountMember {
    email: string
    name: string
    id: string
    selfLink: string
}

export const MerchantAccountBAIsDictionary = {
    // CI: 'Cash In',
    // BB: 'Business to Business',
    // CO: 'Cash Out',
    // BI: 'Money transfer (bank-initiated)',
    // MP: 'Face-to-face Merchant Payment',
    // CP: 'Card Bill Payment',
    // GD: 'Government Disbursement',
    // PP: 'Person to Person',
    // LO: 'Loyalty and Offers',
    // WT: 'Wallet Transfer',
    // BP: 'Non-card Bill Payment',
    // PS: 'Payment for goods and services',
    // MI: 'Merchant Initiated Money Transfer',
    // CD: 'Cash Deposit'
    AA: 'Account to Account',
    FD: 'Funds Disbursement (general)',
    FT: 'Funds Transfer',
    GP: 'Gambling Payment (other than online gambling)',
    MD: 'Merchant Disbursement',
    OG: 'Online Gambling Payout',
    PD: 'Payroll/pension disbursement',
    TU: 'Prepaid Reload / Top Up'
}
export const MerchantAccountBAIsPlusEvenUnusedOnes = {
    AA: 'Account to Account',
    CI: 'Cash In',
    BB: 'Business to Business',
    CO: 'Cash Out',
    BI: 'Money transfer (bank-initiated)',
    MP: 'Face-to-face Merchant Payment',
    CP: 'Card Bill Payment',
    FT: 'Funds Transfer',
    GD: 'Government Disbursement',
    PP: 'Person to Person',
    GP: 'Gambling Payment (other than online gambling)',
    TU: 'Prepaid Reload / Top Up',
    LO: 'Loyalty and Offers',
    WT: 'Wallet Transfer',
    BP: 'Non-card Bill Payment',
    PS: 'Payment for goods and services',
    MI: 'Merchant Initiated Money Transfer',
    CD: 'Cash Deposit',
    MD: 'Merchant Disbursement',
    FD: 'Funds Disbursement (general)',
    OG: 'Online Gambling Payout',
    PD: 'Payroll/pension disbursement'
}
export const MerchantAccountBAIs = Object.keys(MerchantAccountBAIsDictionary)

export const MerchantAccountTTIsDictionary = {
    F07: 'General Person-to-Person Transfer',
    F61: 'Transfer to Own Staged Digital Wallet Account',
    F64: 'Transfer to Own Debit or Prepaid Card Account',
    F54: 'Payment of Own Credit Card Bill',
    F52: 'General Transfer to Own Account',
    F55: 'Business Disbursement',
    C56: 'Government/Non-Profit Disbursement',
    C57: 'Rapid Merchant Settlement',
    F65: 'General Business-to-Business Transfer',
    C07: 'General Person-to-Person Payment Transfer',
    C54: 'Payment of Own Credit Card Bill',
    C52: 'General Transfer to Own Account',
    C55: 'Business Disbursement',
    C65: 'General Business-to-Business Transfer',
    C04: 'Gaming Repay'
}

export const MerchantAccountTTIs = Object.keys(MerchantAccountTTIsDictionary)

export interface MerchantAccount {
    id: string
    name: string
    chBilling?: string
    country: string
    currency: string
    mcc: string
    merchantId: string
    parentMid?: string
    descriptor: string
    timezone: string
    transactionRules: string
    businessApplicationIdentifier?: string
    transactionTypeIdentifier?: string
    trusted?: boolean
    bankAccount: {
        swiftCode: string
        iban: string
        collapsedIban?: string
        note?: string
    }
    metadata: {
        state: string
    }
    apiKeys: {
        token: string
        enabled: boolean
        expiresAt?: string
        selfLink: string
    }[]
    members: MerchantAccountMember[]
    stampsLink: {
        href: string
        name: string
    }[]
    paymentsHeld?: boolean
    selfLink: string
    membersLink: string
}

interface MerchantAccountCardSchemesPricing {
    visa: {
        debit: string
        credit: string
    }
    mastercard: {
        debit: string
        credit: string
    }
}

export interface MerchantAccountDraftContract {
    id: string
    applicationId: string
    state: string
    otherTerms: string
    currency: string
    paymentPeriod: string
    paymentDelay: number
    interchange: boolean
    rollingReserveRate: string
    rollingReserveDelay: number
    charges: [
        {
            id: string
            setupFee: string
            payoutFee: string
            monthlyFee: string
            yearlyFee: string
            minimumPayoutAmount: string
            minimumRollingReservePayoutAmount: string
            minimumDomesticTransactionFee: string
            minimumIntraTransactionFee: string
            minimumInterTransactionFee: string
            interchange?: boolean
            captureLateFee?: string
            authorizationFee: string
            captureFee: string
            refundFee: string
            creditFee: string
            debitFee: string
            seriesFee: string
            retrievalFee: string
            chargebackFee: string
            exchangeFee: string
            threedsFee: string
            schemeRates: {
                id: string
                scheme: 'visa' | 'mastercard'
                segment?: 'business' | 'consumer'
                domesticCredit: string
                domesticDebit: string
                intraRegionCredit: string
                intraRegionDebit: string
                interRegionCredit: string
                interRegionDebit: string
                selfLink: string
            }[]
            selfLink: string
        }
    ]
    loadingStatus: LoadingStatus
    selfLink: string
}

// Indexes is 0 cause they share the same proprieties..
export interface LocallyStoredDraftContract extends Omit<MerchantAccountDraftContract, 'charges'> {
    charges: Omit<MerchantAccountDraftContract['charges'][0], 'schemeRates'>
    rates_visa_consumer: Omit<MerchantAccountDraftContract['charges'][0]['schemeRates'][0], 'scheme' | 'segment'>
    rates_visa_business: Omit<MerchantAccountDraftContract['charges'][0]['schemeRates'][0], 'scheme' | 'segment'>
    rates_mastercard_consumer: Omit<MerchantAccountDraftContract['charges'][0]['schemeRates'][0], 'scheme' | 'segment'>
    rates_mastercard_business: Omit<MerchantAccountDraftContract['charges'][0]['schemeRates'][0], 'scheme' | 'segment'>
}

export interface MerchantAccountDraftTemplate extends MerchantAccountDraftContract {
    label?: string
    gatewayId?: string
}

export interface MerchantAccountContract {
    id: string
    merchantId: string
    currency: string
    mcc: string
    plan: string
    gateway: string
    descriptor: string
    threedSecureMandatory: string
    otherTerms: string
    contact: {
        name: string
        email: string
        phone: string
    }
    company: {
        name: string
        email: string
        phone: string
        tradingNname: string
        registrationNumber: string
        address: string
        zipcodeCity: string
        country: string
        websites: string
        businessModel: string
        estimatedMonthlyTurnover: string
        estimateCurrency: string
    }
    payment: {
        period: string
        delay: number
        minimumAmount: string
    }
    rollingReserve: {
        percentage: string
        delay: number
        minimumAmount: string
    }
    accountFees: {
        base: {
            setup: string
            payout: string
            monthly: string
            yearly: string
            retrieval: string
            chargeback: string
        }
        percentage: {
            exchange: string
        }
    }
    transactionFees: {
        base: {
            authorization: string
            capture: string
            refund: string
            credit: string
            debit: string
        }
        minimum: {
            domestic: string
            intra: string
            inter: string
        }
        percentage: {
            lateCapture: string
            domestic: MerchantAccountCardSchemesPricing
            intra: MerchantAccountCardSchemesPricing
            inter: MerchantAccountCardSchemesPricing
        }
    }
    signer: {
        name: string
        email: string
    }
    metadata: {
        state: string
        createdAt: string
        signedAt: string
        declinedAt: string
        effectiveAt: string
        declinedReason: string
    }
    stampsLink: {
        href: string
        name: string
    }[]
    selfLink: string
    isDraft?: boolean
}
export type MerchantAgreement = MerchantAccountContract

export interface MerchantAccountWithContract extends MerchantAccount {
    loadingStatus: LoadingStatus
    contract: MerchantAccountContract
    draft: LocallyStoredDraftContract
    draftContractLink?: string
    contractHistory?: {
        [key: string]: MerchantAccountContract
    }
    extraDetailsLoaded?: boolean
    contractHistoryLoadingStatus: LoadingStatus
}

export type MerchantAccountsState = {
    forApplication: {
        [applicationId: string]: {
            all: string[]
            loadingStatus: LoadingStatus
        }
    }
    at: {
        [MerchantAccountContractName: string]: MerchantAccountWithContract
    }
}

export const InitialMerchantAccountsState: MerchantAccountsState = {
    forApplication: {},
    at: {}
}
