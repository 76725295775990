import React from 'react'
import { ApplicationCheck } from './Application.Check'
import { CaseSummary } from '@/services/kyc-monitoring/types/cases'
import { Account } from '@/components/general/account'
import moment from 'moment'
import { PeopleAndCompanyCheckSubmittedIncomplete } from './Application.PeopleAndCompanyChecksSubmittedIncomplete'
import { PeopleAndCompanyCheckSubmittedComplete } from './Application.PeopleAndCompanyChecksSubmittedComplete'
import { useCreateCase } from '@/services/kyc-monitoring'
import useScreenWidth from '@/hooks/general/useScreenWidth'

interface PeopleAndCompanyCheckSubmittedProps {
    applicationId: string
    caseSummary: CaseSummary
}

export const PeopleAndCompanyCheckSubmitted: React.FC<PeopleAndCompanyCheckSubmittedProps> = (props) => {
    const { caseSummary, applicationId } = props

    const [createCase, { isLoading: isCreating }] = useCreateCase()

    const handleCreateCase = async () => {
        await createCase({ applicationId })
    }

    const screenWidth = useScreenWidth()
    const showStatus = screenWidth > 1750

    const incompatibleContacts = caseSummary?.response_data?.incompatibleContacts
    const hasIncompleteContacts = incompatibleContacts && incompatibleContacts.length > 0
    const createdAt = moment(caseSummary.created_at).format('D MMM YY')
    const createdAtWithTime = moment(caseSummary.created_at).format('D MMM YY HH:mm')
    const description = `On ${createdAt} by`
    const title = createdAtWithTime
    const status = hasIncompleteContacts ? 'Incomplete' : 'Complete'
    const statusColor = hasIncompleteContacts ? 'warning' : 'success'

    return (
        <ApplicationCheck>
            <ApplicationCheck.Main>
                <ApplicationCheck.Header>
                    <ApplicationCheck.Title>People and company checks</ApplicationCheck.Title>
                    <ApplicationCheck.Description title={title}>
                        {description} <Account id={caseSummary.author} />
                    </ApplicationCheck.Description>
                </ApplicationCheck.Header>
                <ApplicationCheck.StatusAndAction>
                    {showStatus && (
                        <ApplicationCheck.Status testid="people-check-complete-status" color={statusColor}>
                            {status}
                        </ApplicationCheck.Status>
                    )}
                    {hasIncompleteContacts && (
                        <ApplicationCheck.Action
                            testid="people-check-resubmit"
                            onClick={handleCreateCase}
                            isLoading={isCreating}
                        >
                            Re-submit
                        </ApplicationCheck.Action>
                    )}
                </ApplicationCheck.StatusAndAction>
            </ApplicationCheck.Main>
            <ApplicationCheck.AdditionalInfo>
                {hasIncompleteContacts ? (
                    <PeopleAndCompanyCheckSubmittedIncomplete
                        caseSummary={caseSummary}
                        isLoading={isCreating}
                        onResubmit={handleCreateCase}
                    />
                ) : (
                    <PeopleAndCompanyCheckSubmittedComplete applicationId={applicationId} caseSummary={caseSummary} />
                )}
            </ApplicationCheck.AdditionalInfo>
        </ApplicationCheck>
    )
}
