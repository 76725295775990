import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { Flex } from '@/components/layout/flex'
import { Text as TextComp } from '@/components/general/text'
import React from 'react'
import { Spacer } from '@/components/layout/spacer'
import { Tag } from '../MerchantTag'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { Separator } from '@/components/layout/separator'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { TextLineLoader } from '@/components/loaders/textLineLoader'
import { ExternalLink } from '@/components/buttons/externalLink'

interface ApplicationCheckProps {
    children: React.ReactNode
}

const ApplicationCheck = (props: ApplicationCheckProps) => {
    const { children } = props

    return (
        <Card background="back.background.strongerI" shadowLess>
            <CardInset>
                <Flex column gap={16}>
                    {children}
                </Flex>
            </CardInset>
        </Card>
    )
}

interface MainProps {
    children: React.ReactNode
}

const Main: React.FC<MainProps> = (props) => {
    const { children } = props

    return (
        <Flex align="center" justify="space-between">
            {children}
        </Flex>
    )
}

interface HeaderProps {
    children: React.ReactNode
}

const Header: React.FC<HeaderProps> = (props) => {
    const { children } = props

    return (
        <Flex column gap={4}>
            {children}
        </Flex>
    )
}

interface TitleProps {
    children: string
}

const Title: React.FC<TitleProps> = (props) => {
    const { children } = props

    return <TextComp bold>{children}</TextComp>
}

interface DescriptionProps {
    children: React.ReactNode | string
    title?: string
}

const Description: React.FC<DescriptionProps> = (props) => {
    const { children, title } = props

    return (
        <TextComp title={title} color="front.text.subtlerI">
            {children}
        </TextComp>
    )
}

interface TextProps {
    children: React.ReactNode | string
}

const Text: React.FC<TextProps> = (props) => {
    const { children } = props

    return <TextComp color="front.text.subtlerI">{children}</TextComp>
}

interface StatusAndActionProps {
    children: React.ReactNode
}

const StatusAndAction: React.FC<StatusAndActionProps> = (props) => {
    const { children } = props

    return <Flex gap={8}>{children}</Flex>
}

interface StatusProps {
    testid?: string
    children: string
    color: 'warning' | 'success'
}

const Status: React.FC<StatusProps> = (props) => {
    const { testid, children, color } = props

    const colorName = color === 'success' ? 'green' : 'orange'

    return (
        <Flex cy={testid} align="center">
            <Tag s={colorName}>{children}</Tag>
        </Flex>
    )
}

interface ActionProps {
    testid?: string
    children: string
    isLoading?: boolean
    onClick: () => void
}

const Action: React.FC<ActionProps> = (props) => {
    const { testid, children, isLoading, onClick } = props

    return (
        <Flex>
            <SimpleButton cy={testid} background="front.background" onClick={onClick}>
                <ButtonLoadingCover background="front.background" watcher={isLoading ? 'started' : undefined}>
                    <ButtonInset>{children}</ButtonInset>
                </ButtonLoadingCover>
            </SimpleButton>
        </Flex>
    )
}

interface InlineActionProps {
    children: string
    isLoading?: boolean
    url?: string
    onClick?: () => void
}

const InlineAction: React.FC<InlineActionProps> = (props) => {
    const { children, isLoading, url, onClick } = props

    if (isLoading) {
        return <>{children}</>
    }

    return (
        <ExternalLink marginRight="none" target="blank" arrowLess onClick={onClick} url={url}>
            {children}
        </ExternalLink>
    )
}

interface AdditionalInfoProps {
    children: React.ReactNode
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = (props) => {
    const { children } = props

    return (
        <Flex column>
            <Separator type="solid" background="back.background.strongerI" />
            <Spacer height={16} />
            {children}
        </Flex>
    )
}

const Loader: React.FC = () => {
    return (
        <Flex align="center">
            <TextLineLoader />
        </Flex>
    )
}

ApplicationCheck.Main = Main
ApplicationCheck.Header = Header
ApplicationCheck.Title = Title
ApplicationCheck.Description = Description
ApplicationCheck.Text = Text
ApplicationCheck.StatusAndAction = StatusAndAction
ApplicationCheck.Status = Status
ApplicationCheck.Action = Action
ApplicationCheck.InlineAction = InlineAction
ApplicationCheck.AdditionalInfo = AdditionalInfo
ApplicationCheck.Loader = Loader

export { ApplicationCheck }
