import { useCreateCase } from '@/services/kyc-monitoring'
import { ApplicationCheck } from './Application.Check'
import { CaseSummary } from '@/services/kyc-monitoring/types/cases'

const CLEVER_KYC_LINK = import.meta.env.VITE_CLEVER_KYC_LINK

interface PeopleAndCompanyCheckSubmittedCompleteProps {
    applicationId: string
    caseSummary: CaseSummary
}

export const PeopleAndCompanyCheckSubmittedComplete: React.FC<PeopleAndCompanyCheckSubmittedCompleteProps> = (
    props
) => {
    const { applicationId, caseSummary } = props

    const [createCase, { isLoading: isCreating }] = useCreateCase()

    const handleCreateCase = async () => {
        await createCase({ applicationId })
    }

    const accountUrl = caseSummary?.account_link ? caseSummary?.account_link : CLEVER_KYC_LINK

    return (
        <ApplicationCheck.Text>
            See result in <ApplicationCheck.InlineAction url={accountUrl}>CleverKYC</ApplicationCheck.InlineAction> or{' '}
            <ApplicationCheck.InlineAction isLoading={isCreating} onClick={handleCreateCase}>
                re-submit
            </ApplicationCheck.InlineAction>{' '}
            if new info has been added.
        </ApplicationCheck.Text>
    )
}
