import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Flex } from '../../../components/layout/flex'
import { List } from '../../../components/layout/list'
import { Spacer } from '../../../components/layout/spacer'
import { ModalPage } from '../../../components/layout/modalPage'
import { ModalPageInset } from '../../../components/layout/modalPageInset'
import { ModalHeader } from '../../../components/modals/modalHeader'
import { useForm } from '../../../hooks/general/useForm'
import { useNamedWatcherWithContext } from '../../../hooks/general/useWatcher'
import { MerchantAccountsActions } from '../../../store/merchantAccounts/actions'
import { CountriesDictionaryByTimezone, CountriesListByTimezone } from '../../../utils/countries'
import { uppercaseFirstLetter } from '../../../utils'
import { CardSection } from '../../../components/cards/cardSection'
import { FormHandler } from '../../../components/forms/formHandler'
import { RootState } from '@/store'
import { TextareaInput } from '../../../components/forms/textareaInput'
import { TransactionRulesValidator } from './Accounts.IDTransactionRulesValidator'
import styled from 'styled-components'
import { Color } from '../../../styles/colors'
import { Text } from '../../../components/general/text'
import { useMerchantName } from '../../../hooks/general/useMerchantName'
import { MerchantAccountBAIs, MerchantAccountBAIsDictionary, MerchantAccountTTIs, MerchantAccountTTIsDictionary } from '../../../store/merchantAccounts/types'
import { ExternalLink } from '../../../components/buttons/externalLink'
import { HelpTip } from '../../../components/general/helpTip'
import { AvailableSettlementCurrencies } from '../../../store/settlements/types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const YESNO = [true, false]

type Params = { id: string }

const changesFieldName = (k: string) => uppercaseFirstLetter(k)
export const MerchantAccountNew: React.FC = () => {
    const dispatch = useDispatch()
    const { id } = useParams() as Params

    const [watcher, watcherId, watcherContext] = useNamedWatcherWithContext(
        `Merchant.AccountsPage.Account.NewAccount.Save`
    )
    const location = useLocation()
    const loadingStatus = useSelector((state: RootState) => state.merchantAccounts.forApplication[id]?.loadingStatus)
    const lastAccount = useSelector((state: RootState) => {
        const latestAccountId = state.merchantAccounts.forApplication[id]?.all?.[0]
        const accountToCopy = (location.state as any)?.selectedAccount || latestAccountId
        return state.merchantAccounts?.at?.[accountToCopy]
    })

    const navigate = useNavigate()

    useEffect(() => {
        if (watcher === 'success')
            navigate({
                pathname: `/merchant/${id}/accounts/${watcherContext.accountId}`,
                search: location.search
            })
    }, [navigate, id, watcher, location, watcherContext])

    const onSubmit = useCallback(
        (form) => {
            dispatch(MerchantAccountsActions.CREATE_ACCOUNT(watcherId, form.data, id))
        },
        [dispatch, watcherId, id]
    )

    const { formRef, form, submitHandler, resetForm, touched, focusInvalidField, errors } = useForm(
        watcher,
        undefined,
        undefined,
        undefined,
        true
    )

    const textForChange = useCallback((label: string, val: any) => {
        if (val === true) return 'Yes'
        if (val === false) return 'No'
        return val
    }, [])

    const textForTimezone = useCallback((id: any) => {
        if (!id) return ''
        return `${CountriesDictionaryByTimezone?.[id]?.name} - ${CountriesDictionaryByTimezone?.[id]?.timezone}`
    }, [])

    const name = useMerchantName(id, `New account`)

    return (
        <ModalPage
            title={name}
            pageId="Merchant.ApplicationPage.FieldEdits"
            isLoading={loadingStatus !== 'done'}
            backTo={`/merchant/${id}/accounts/${(location.state as any)?.selectedAccount || ''}`}
        >
            <ModalHeader
                title={'Create a new merchant account'}
                pageId="Merchant.ApplicationPage.FieldEdits"
                backTo={`/merchant/${id}/accounts/${(location.state as any)?.selectedAccount || ''}`}
            />
            <ModalPageInset>
                <Flex justify="center" align="center" grow column>
                    <Card higher title={'Create a new account'}>
                        <CardInset>
                            <Flex>
                                <Spacer height={20} />
                                <List
                                    background="front.background"
                                    items={{
                                        'Name': {
                                            key: 'name',
                                            type: 'input',
                                            placeholder: 'Account name',
                                            initialValue: lastAccount?.name,
                                            overBackground: 'front.background',
                                            validation: errors.name,
                                            ref: (ref) => formRef(ref, 'name', yup.string().required())
                                        },
                                        'Currency': {
                                            key: 'currency',
                                            type: 'select',
                                            textForItem: currencyTextForItem,
                                            selected: lastAccount?.currency,
                                            placeholder: 'Select a currency',
                                            overBackground: 'front.background',
                                            dropdownId: 'Merchant.AccountsPage.Account.CurrencyList',
                                            items: AvailableSettlementCurrencies,
                                            validation: errors.currency,
                                            ref: (ref) => formRef(ref, 'currency', yup.string().required())
                                        },
                                        'Descriptor': {
                                            key: 'descriptor',
                                            type: 'input',
                                            placeholder: 'Account descriptor',
                                            overBackground: 'front.background',
                                            initialValue: lastAccount?.descriptor,
                                            validation: errors.descriptor,
                                            ref: (ref) =>
                                                formRef(ref, 'descriptor', yup.string().required().descriptor())
                                        },
                                        'Separator 1': {
                                            key: 'separator-1',
                                            type: 'separator',
                                            node: <Spacer height={10} />
                                        },
                                        'MCC': {
                                            key: 'mcc',
                                            type: 'autocomplete',
                                            allowAnyInput: true,
                                            placeholder: 'MCC',
                                            forEntity: 'mcc',
                                            selected: lastAccount?.mcc,
                                            dropdownId: 'Merchant.AccountsPage.Account.MCCList',
                                            overBackground: 'front.background',
                                            validation: errors.mcc,
                                            ref: (ref) => formRef(ref, 'mcc', yup.string().required())
                                        },
                                        'Timezone': {
                                            key: 'timezone',
                                            type: 'select',
                                            placeholder: 'Test',
                                            items: CountriesListByTimezone,
                                            selected: lastAccount?.timezone,
                                            textForItem: textForTimezone,
                                            dropdownId: 'Merchant.AccountsPage.Account.CountriesList',
                                            overBackground: 'front.background',
                                            validation: errors.timezone,
                                            ref: (ref) => formRef(ref, 'timezone', yup.string().required())
                                        },
                                        'Parent MID': {
                                            key: 'parent-mid',
                                            type: 'input',
                                            initialValue: lastAccount?.parentMid,
                                            placeholder: 'Enter Parent MID',
                                            overBackground: 'front.background',
                                            validation: errors.parentMid,
                                            ref: (ref) =>
                                                formRef(
                                                    ref,
                                                    'parentMid',
                                                    yup
                                                        .string()
                                                        .matches(/^[0-9]*$/, 'Should only contain digits')
                                                        .length(8, 'Should be 8 digits')
                                                        .required()
                                                )
                                        },
                                        'BAI': {
                                            key: 'bai',
                                            type: 'select',
                                            placeholder: '-',
                                            items: MerchantAccountBAIs,
                                            selected: lastAccount?.businessApplicationIdentifier,
                                            textForItem: (k) => {
                                                if (!k) return undefined
                                                return `${k} - ${
                                                    (MerchantAccountBAIsDictionary as any)?.[k] || 'Unknown'
                                                }`
                                            },
                                            dropdownId: 'Merchant.AccountsPage.Account.CountriesList',
                                            overBackground: 'front.background',
                                            helpNode: (
                                                <HelpTip>
                                                    <Text color="floating.text">
                                                        Visa&apos;s&nbsp;
                                                        <ExternalLink
                                                            defaultColor="floating.subtleAccent.text"
                                                            target="blank"
                                                            // eslint-disable-next-line max-len
                                                            url="https://developer.visa.com/guides/request_response_codes#business_application_identifier"
                                                        >
                                                            Business Application Identifier
                                                        </ExternalLink>
                                                    </Text>
                                                </HelpTip>
                                            ),
                                            ref: (ref) => formRef(ref, 'business_application_identifier', yup.string())
                                        },
                                        'TTI': {
                                            key: 'tti',
                                            type: 'select',
                                            placeholder: '-',
                                            items: MerchantAccountTTIs,
                                            selected: lastAccount?.transactionTypeIdentifier,
                                            textForItem: (k) => {
                                                if (!k) return undefined
                                                return `${k} - ${
                                                    (MerchantAccountTTIsDictionary as any)?.[k] || 'Unknown'
                                                }`
                                            },
                                            dropdownId: 'Merchant.AccountsPage.Account.TTI',
                                            overBackground: 'front.background',
                                            helpNode: (
                                                <HelpTip>
                                                    <Text color="floating.text">
                                                        Transaction Type Identifier (Mastercard MoneySend)
                                                    </Text>
                                                </HelpTip>
                                            ),
                                            ref: (ref) => formRef(ref, 'transaction_type_identifier', yup.string())
                                        },
                                        'Trusted': {
                                            key: 'trusted',
                                            type: 'select',
                                            textForItem: questionTextForItem,
                                            selected: lastAccount?.trusted,
                                            overBackground: 'front.background',
                                            dropdownId: 'Merchant.AccountsPage.Account.Trusted',
                                            items: YESNO,
                                            isQuestion: true,
                                            validation: errors.trusted,
                                            ref: (ref) => formRef(ref, 'trusted', yup.string())
                                        },
                                        'Separator 2': {
                                            key: 'separator-2',
                                            type: 'separator',
                                            node: <Spacer height={10} />
                                        },
                                        'Clearhaus Billing': {
                                            key: 'clearhaus-billing',
                                            type: 'select',
                                            textForItem: questionTextForItem,
                                            selected: true,
                                            isDisabled: true,
                                            overBackground: 'front.background',
                                            dropdownId: 'Merchant.AccountsPage.Account.Billing',
                                            items: YESNO,
                                            isQuestion: true,
                                            validation: errors.chBilling,
                                            ref: (ref) => formRef(ref, 'chBilling', yup.string().required())
                                        },
                                        'PH (Payments held)': {
                                            key: 'payments-held',
                                            type: 'select',
                                            textForItem: questionTextForItem,
                                            selected: false,
                                            shouldHide:
                                                form?.data.chBilling !== undefined
                                                    ? form.data.chBilling !== true
                                                    : false,
                                            overBackground: 'front.background',
                                            dropdownId: 'Merchant.AccountsPage.Account.PaymentsHeld',
                                            items: YESNO,
                                            isQuestion: true,
                                            validation: errors.paymentsHeld,
                                            ref: (ref) => formRef(ref, 'paymentsHeld', yup.string())
                                        }
                                    }}
                                    switchToRowsAt={10000}
                                    cellHorizontalTemplate="140px minmax(auto, 200px)"
                                />

                                <TransactionHolder
                                    background={
                                        errors.transactionRules
                                            ? `front.subtleDanger.background.strongerI`
                                            : `front.subtleSuccess.background`
                                    }
                                >
                                    <Flex column grow align="stretch">
                                        <Text bold>Account transaction rules</Text>
                                        <Spacer height={12} />
                                        <TextareaInput
                                            overBackground="front.background"
                                            placeholder="Transaction rules"
                                            initialValue={lastAccount?.transactionRules}
                                            // eslint-disable-next-line max-len
                                            isSeamless
                                            monotype
                                            cy="transactionRules"
                                            skipAnimation
                                            // validation={errors.transactionRules}
                                            ref={(ref) =>
                                                formRef(ref, 'transactionRules', yup.string().required().suez())
                                            }
                                        />
                                    </Flex>
                                    <Spacer height={12} />
                                    <Flex justify="space-between" align="flex-start">
                                        {touched.transactionRules ? (
                                            <TransactionRulesValidator error={errors.transactionRules} />
                                        ) : (
                                            <span />
                                        )}
                                    </Flex>
                                </TransactionHolder>
                            </Flex>
                            <Spacer height={20} />
                            <CardSection background="subtleBlue">
                                <CardInset>
                                    <Flex column align="stretch">
                                        <Flex justify="flex-end" align="center">
                                            <FormHandler
                                                brandNewForm={true}
                                                formSubmitLabel={'Create account'}
                                                textForFieldName={changesFieldName}
                                                changes={form.changes}
                                                errors={errors}
                                                focusInvalidField={focusInvalidField}
                                                textForChange={textForChange}
                                                submitForm={submitHandler(onSubmit)}
                                                submitWatcherId={watcherId}
                                                resetForm={resetForm}
                                            />
                                        </Flex>
                                    </Flex>
                                </CardInset>
                            </CardSection>
                        </CardInset>
                    </Card>
                    <Spacer height={50} />
                </Flex>
            </ModalPageInset>
        </ModalPage>
    )
}

const TransactionHolder = styled.div<{ background: Color }>`
    margin-right: -25px;
    margin-top: -25px;
    margin-bottom: -40px;
    min-width: 400px;
    margin-left: 20px;
    background-color: ${(p) => p.theme[p.background]};
    border-left: 1px solid ${(p) => p.theme['front.background.strongerII']};
    padding: 15px 20px 20px 20px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    border-radius: 0;
`

const questionTextForItem = (e: any) => {
    if (e === true) return 'Yes'
    return 'No'
}

const currencyTextForItem = (e: any) => {
    if (!e) return undefined
    return e
}
