import React from 'react'
import { ApplicationCheck } from './Application.Check'
import { useCreateCase } from '@/services/kyc-monitoring'
import useScreenWidth from '@/hooks/general/useScreenWidth'

interface PeopleAndCompanyCheckUnsubmittedProps {
    applicationId: string
}

const PeopleAndCompanyCheckUnsubmitted: React.FC<PeopleAndCompanyCheckUnsubmittedProps> = (props) => {
    const { applicationId } = props

    const screenWidth = useScreenWidth()

    const [createCase, { isLoading: isCreating }] = useCreateCase()

    const handleCreateCase = async () => {
        await createCase({ applicationId })
    }

    const showStatus = screenWidth > 1750

    return (
        <ApplicationCheck>
            <ApplicationCheck.Main>
                <ApplicationCheck.Header>
                    <ApplicationCheck.Title>People and company checks</ApplicationCheck.Title>
                    <ApplicationCheck.Description>Ready to submit merchant</ApplicationCheck.Description>
                </ApplicationCheck.Header>
                <ApplicationCheck.StatusAndAction>
                    {showStatus && <ApplicationCheck.Status color="warning">Unsubmitted</ApplicationCheck.Status>}
                    <ApplicationCheck.Action
                        testid="people-check-submit"
                        onClick={handleCreateCase}
                        isLoading={isCreating}
                    >
                        Submit
                    </ApplicationCheck.Action>
                </ApplicationCheck.StatusAndAction>
            </ApplicationCheck.Main>
        </ApplicationCheck>
    )
}

export { PeopleAndCompanyCheckUnsubmitted }
