import { forwardRef } from 'react'
import styled, { css, RuleSet } from 'styled-components'
import { Color } from '../../styles/colors'

type Size = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'

const TextWRef: React.ForwardRefRenderFunction<
    any,
    {
        size?: Size
        color?: Color
        bold?: boolean
        upper?: boolean
        id?: string
        showSpaces?: boolean
        align?: 'left' | 'center' | 'right'
        children: React.ReactNode
        cssOverrides?: RuleSet<any>
        style?: React.CSSProperties
        className?: string
        italic?: boolean
        noWrap?: boolean
        mono?: boolean
        underlined?: boolean
        strike?: boolean
        notSelectable?: boolean
        cy?: string
        oneLine?: boolean
        title?: string
    }
> = (
    {
        size = 'm',
        children,
        color,
        cssOverrides,
        bold = false,
        align = 'left',
        upper = false,
        mono,
        underlined,
        cy,
        showSpaces = false,
        className,
        strike,
        style,
        noWrap,
        oneLine,
        italic,
        id,
        notSelectable,
        title
    },
    ref
) => {
    return (
        <Span
            size={size}
            textColor={color}
            bold={bold}
            align={align}
            showSpaces={showSpaces}
            className={className}
            upper={upper}
            style={style}
            mono={mono}
            underlined={underlined}
            strike={strike}
            italic={italic}
            data-cy={cy}
            cssOverrides={cssOverrides}
            notSelectable={notSelectable}
            id={id}
            ref={ref}
            noWrap={noWrap}
            oneLine={oneLine}
            title={title}
        >
            {children}
        </Span>
    )
}

interface ISpan {
    size: Size
    textColor?: Color
    bold: boolean
    upper: boolean
    mono?: boolean
    noWrap?: boolean
    italic?: boolean
    strike?: boolean
    underlined?: boolean
    cssOverrides?: RuleSet<any>
    align: 'left' | 'center' | 'right'
    notSelectable?: boolean
    oneLine?: boolean
    showSpaces?: boolean
}

const Span = styled.span<ISpan>`
    font-family: 'Roboto', sans-serif;

    @media print {
        font-size: 10px;
        line-height: 13px;
        display: inline;
        margin-top: 3px;
    }

    white-space: normal;
    ${(p) =>
        p.noWrap
            ? css`
                  white-space: nowrap;
              `
            : css`
                  word-break: break-word;
              `}
    ${(p) =>
        p.align &&
        css`
            text-align: ${p.align};
        `}

    ${(p) =>
        p.bold &&
        css`
            font-weight: 500;
        `}

    ${(p) =>
        p.bold === false &&
        css`
            font-weight: 400;
        `}

    ${(p) =>
        p.mono &&
        css`
            &,
            * {
                font-family: 'Roboto Mono', mono;
                font-size: 11px !important;
                letter-spacing: -0.4px !important;
                font-weight: 500 !important;
            }
        `}
    ${(p) =>
        p.mono &&
        p.bold &&
        css`
            font-weight: 500;
        `}

    ${(p) =>
        p.underlined &&
        css`
            text-decoration: underline;
        `}

    ${(p) =>
        p.upper &&
        css`
            text-transform: uppercase;
        `}
        
    ${(p) =>
        p.strike &&
        css`
            text-decoration: line-through;
        `}

    ${(p) =>
        p.size === 'xs' &&
        css`
            font-size: 11px;
            font-weight: 400;
            letter-spacing: -0.15px;
            line-height: 17px;

            ${p.bold &&
            css`
                font-weight: 500;
                font-size: 10px;
                letter-spacing: 0.15px;
            `}
        `}

    ${(p) =>
        p.size === 's' &&
        css`
            font-size: 11px;
            line-height: 15px;
            letter-spacing: 0px;
            ${p.mono &&
            css`
                letter-spacing: -0.5px;
            `}
            ${p.bold &&
            css`
                /* letter-spacing: 0.1px; */
                font-weight: 500;
            `}
        `}

    ${(p) =>
        p.size === 'm' &&
        css`
            font-size: 13px;
            line-height: 19px;

            ${p.mono &&
            css`
                letter-spacing: -0.5px;
            `}
        `}

    ${(p) =>
        p.size === 'l' &&
        css`
            font-size: 17px;
            letter-spacing: 0px;

            ${p.bold &&
            css`
                font-weight: 500;
            `}
        `}

    ${(p) =>
        p.size === 'xl' &&
        css`
            font-size: 22px;
            letter-spacing: -0.1px;
            font-weight: 600;
        `}

    ${(p) =>
        p.size === 'xxl' &&
        css`
            font-style: normal;
            line-height: 34px;
            font-size: 26px;
            font-weight: 600;
            letter-spacing: 0.2px;

            @media print {
                font-size: 25px;
                line-height: 32px;
            }
        `}

    ${(p) => p.cssOverrides && p.cssOverrides}

    b {
        font-weight: 500;
    }

    em {
        font-style: normal;
        font-weight: 300;
        opacity: 0.4;
    }
    ${(p) =>
        p.oneLine &&
        css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `}
    ${(p) =>
        p.notSelectable &&
        css`
            user-select: none;
            cursor: pointer;
        `}
    
    ${(p) =>
        p.underlined &&
        css`
            text-decoration: underline;
        `}

    ${(p) =>
        p.textColor &&
        css`
            color: ${p.theme[p.textColor]};
        `}
    
    
    ${(p) =>
        p.showSpaces &&
        css`
            white-space: pre-line;
        `}
    ${(p) =>
        p.italic &&
        css`
            font-style: italic;
        `}
`

export const Text = forwardRef(TextWRef)
