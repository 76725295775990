import { forwardRef } from 'react'

const FlexWRef: React.ForwardRefRenderFunction<
    any,
    {
        justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between'
        align?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'baseline' | 'stretch'
        grow?: boolean
        column?: boolean
        padding?: number
        marginLeft?: number
        marginTop?: number
        top?: number
        basis?: string
        noShrink?: boolean
        fullWidth?: boolean
        shrink?: boolean
        children?: React.ReactNode
        style?: React.CSSProperties
        onMouseEnter?: () => void
        onMouseLeave?: () => void
        wrap?: boolean
        gap?: number
        cy?: string
        className?: string
    }
> = (
    {
        justify,
        align,
        column,
        children,
        padding = 0,
        style,
        onMouseEnter,
        basis,
        grow,
        fullWidth,
        wrap,
        marginLeft,
        top,
        shrink,
        noShrink,
        onMouseLeave,
        marginTop,
        gap,
        className,
        cy
    },
    ref
) => {
    const styles: React.CSSProperties = {
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
        flexWrap: wrap ? 'wrap' : undefined,
        flexShrink: noShrink ? 0 : 0.5,
        minWidth: 0,
        ...(basis ? { flexBasis: basis } : {}),
        ...(shrink ? { flexShrink: 1 } : {}),
        ...(noShrink ? { minWidth: 0 } : {}),
        ...(fullWidth ? { width: '100%' } : {})
    }

    if (grow) styles.flexGrow = 1
    if (align) styles.alignItems = align

    if (justify) styles.justifyContent = justify
    if (marginLeft) styles.marginLeft = marginLeft
    if (marginTop) styles.marginTop = marginTop
    if (top) {
        styles.position = 'relative'
        styles.top = top
    }

    if (padding) styles.padding = `${padding}px`
    if (gap) styles.gap = `${gap}px`

    return (
        <div
            ref={ref}
            data-cy={cy}
            onMouseEnter={onMouseEnter}
            className={className}
            onMouseLeave={onMouseLeave}
            style={{
                ...styles,
                ...style
            }}
        >
            {children}
        </div>
    )
}

export const Flex = forwardRef(FlexWRef)
