import type { TypedMutationTrigger } from '@reduxjs/toolkit/query/react'

import { ApplicationCheck } from './Application.Check'
import { CaseSummary } from '@/services/kyc-monitoring/types/cases'

const CLEVER_KYC_LINK = import.meta.env.VITE_CLEVER_KYC_LINK

interface PeopleAndCompanyCheckSubmittedIncompleteProps {
    caseSummary: CaseSummary
    onResubmit: () => void
    isLoading: boolean
}

export const PeopleAndCompanyCheckSubmittedIncomplete: React.FC<PeopleAndCompanyCheckSubmittedIncompleteProps> = (
    props
) => {
    const { caseSummary, isLoading, onResubmit } = props

    const accountUrl = caseSummary?.account_link ? caseSummary?.account_link : CLEVER_KYC_LINK

    return (
        <ApplicationCheck.Text>
            Latest submission had incomplete info in the people section. See result in{' '}
            <ApplicationCheck.InlineAction url={accountUrl}>CleverKYC</ApplicationCheck.InlineAction> or{' '}
            <ApplicationCheck.InlineAction isLoading={isLoading} onClick={onResubmit}>
                re-submit
            </ApplicationCheck.InlineAction>{' '}
            after adding missing info.
        </ApplicationCheck.Text>
    )
}
